import React, { useState } from 'react'
import { graphql, PageProps } from 'gatsby'

import Footer from '../components/Footer'
import Layout from '../Layout'
import SEO from '../SEO'

import { Archive } from '../util/types'

interface Props extends PageProps {
    data: {
        sanityArchiveSearch: {
            archives: Archive[]
            types: {
                description: string
                name: string
            }[]
        }
    }
}

const ArchiveResults: React.FC<Props> = ({ data, location }) => {
    const [group1Open, setGroup1Open] = useState(false)
    const [group2Open, setGroup2Open] = useState(false)
    const [group3Open, setGroup3Open] = useState(false)
    const [group4Open, setGroup4Open] = useState(false)

    // Results has format: ["A2", "B3", "C1", "D2"]
    const results = location.search.replace('?r=', '').split(',')

    const { archives, types } = data.sanityArchiveSearch

    const filteredArchives: Archive[] = archives
        .filter(archive => {
            // A1 | A2                  -> A,A1,A1.x | A,A2,A2.x
            // B1 | B3                  -> B,B1 | B,B3
            // C1 | C2 | C3 | C4        -> C,C1 | C,C2 | C,C3 | C,C4
            // D1 | D2                  -> D,D1 | D,D2

            // Requirements has format: "A,B1,C,D2"
            const requirements = archive.requirements.replaceAll(' ', '')

            // Go over each answer & check if it satisfies the corresponding requirement
            // I.e. Either the specific version is present, or the general version
            return (
                results.filter(
                    r =>
                        requirements.includes(r) ||
                        requirements.includes(r[0] + ',') ||
                        requirements.indexOf(r[0]) === requirements.length - 1
                ).length === results.length
            )
        })
        .sort((a, b) => (a.name > b.name ? 1 : -1))

    return (
        <div>
            <Layout currentPage="archivsuche">
                <SEO title="Was Wa(h)r - Archivsuche" />
            </Layout>

            <div className="archive">
                <p className="archive-heading">Diese Archive könnten für dich interessant sein:</p>
                {filteredArchives.filter(archive => archive.requirements.includes('A1.1')).length >
                    0 && (
                    <div className="archive-group">
                        <button
                            className={`archive-group-toggle ${
                                group1Open ? 'button-active archive-group-toggle-active' : ''
                            }`}
                            onClick={() => setGroup1Open(!group1Open)}
                        >
                            {'>>>'} Archive aus Süddeutschland
                        </button>

                        <div
                            className={`archive-group-items ${
                                group1Open ? 'archive-group-items-open' : ''
                            }`}
                        >
                            {filteredArchives
                                .filter(archive => archive.requirements.includes('A1.1'))
                                .map(({ name, url, type }, i) => (
                                    <a
                                        href={url}
                                        target="_blank"
                                        key={i}
                                        className="archive-button"
                                    >
                                        <button>
                                            {name}
                                            <span>{'([{ }])'}</span>
                                            <span>
                                                {types.filter(t => t.name === type)[0]
                                                    .description || 'No description'}
                                            </span>
                                            <i>{type}</i>
                                        </button>
                                    </a>
                                ))}
                        </div>
                    </div>
                )}

                {filteredArchives.filter(archive => archive.requirements.includes('A1.4')).length >
                    0 && (
                    <div className="archive-group">
                        <button
                            className={`archive-group-toggle ${
                                group2Open ? 'button-active archive-group-toggle-active' : ''
                            }`}
                            onClick={() => setGroup2Open(!group2Open)}
                        >
                            {'>>>'} Archive aus Norddeutschland
                        </button>

                        <div
                            className={`archive-group-items ${
                                group2Open ? 'archive-group-items-open' : ''
                            }`}
                        >
                            {filteredArchives
                                .filter(archive => archive.requirements.includes('A1.4'))
                                .map(({ name, url, type }, i) => (
                                    <a
                                        href={url}
                                        target="_blank"
                                        key={i}
                                        className="archive-button"
                                    >
                                        <button>
                                            {name}
                                            <span>{'([{ }])'}</span>
                                            <span>
                                                {types.filter(t => t.name === type)[0]
                                                    .description || 'No description'}
                                            </span>
                                            <i>{type}</i>
                                        </button>
                                    </a>
                                ))}
                        </div>
                    </div>
                )}

                {filteredArchives.filter(archive => archive.requirements.includes('A1.2')).length >
                    0 && (
                    <div className="archive-group">
                        <button
                            className={`archive-group-toggle ${
                                group3Open ? 'button-active archive-group-toggle-active' : ''
                            }`}
                            onClick={() => setGroup3Open(!group3Open)}
                        >
                            {'>>>'} Archive aus Westdeutschland
                        </button>

                        <div
                            className={`archive-group-items ${
                                group3Open ? 'archive-group-items-open' : ''
                            }`}
                        >
                            {filteredArchives
                                .filter(archive => archive.requirements.includes('A1.2'))
                                .map(({ name, url, type }, i) => (
                                    <a
                                        href={url}
                                        target="_blank"
                                        key={i}
                                        className="archive-button"
                                    >
                                        <button>
                                            {name}
                                            <span>{'([{ }])'}</span>
                                            <span>
                                                {types.filter(t => t.name === type)[0]
                                                    .description || 'No description'}
                                            </span>
                                            <i>{type}</i>
                                        </button>
                                    </a>
                                ))}
                        </div>
                    </div>
                )}

                {filteredArchives.filter(archive => archive.requirements.includes('A1.3')).length >
                    0 && (
                    <div className="archive-group">
                        <button
                            className={`archive-group-toggle ${
                                group4Open ? 'button-active archive-group-toggle-active' : ''
                            }`}
                            onClick={() => setGroup4Open(!group4Open)}
                        >
                            {'>>>'} Archive aus Ostdeutschland
                        </button>

                        <div
                            className={`archive-group-items ${
                                group4Open ? 'archive-group-items-open' : ''
                            }`}
                        >
                            {filteredArchives
                                .filter(archive => archive.requirements.includes('A1.3'))
                                .map(({ name, url, type }, i) => (
                                    <a
                                        href={url}
                                        target="_blank"
                                        key={i}
                                        className="archive-button"
                                    >
                                        <button>
                                            {name}
                                            <span>{'([{ }])'}</span>
                                            <span>
                                                {types.filter(t => t.name === type)[0]
                                                    .description || 'No description'}
                                            </span>
                                            <i>{type}</i>
                                        </button>
                                    </a>
                                ))}
                        </div>
                    </div>
                )}

                {filteredArchives
                    .filter(archive => !archive.requirements.includes('A1.'))
                    .map(({ name, url, type }, i) => (
                        <a href={url} target="_blank" key={i} className="archive-button">
                            <button>
                                {name}
                                <span>{'([{ }])'}</span>
                                <span>
                                    {types.filter(t => t.name === type)[0]?.description || type}
                                </span>
                                <i>{type}</i>
                            </button>
                        </a>
                    ))}
            </div>

            <Footer />
        </div>
    )
}

export const query = graphql`
    query {
        sanityArchiveSearch {
            archives {
                name
                requirements
                type
                url
            }
            types {
                description
                name
            }
        }
    }
`

export default ArchiveResults
